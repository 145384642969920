@font-face {
  font-family: 'Nyata';
  src: url('/nyata-extrabold-webfont.woff2') format('woff2'),
  url('/nyata-extrabold-webfont.woff2') format('woff');
  font-weight: 900;
}
@font-face {
  font-family: 'Nyata';
  src: url('/nyata-extrabold-webfont.woff2') format('woff2'),
  url('/nyata-extrabold-webfont.woff2') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'Nyata';
  src: url('/nyata-extrabold-webfont.woff22') format('woff2'),
  url('/nyata-extrabold-webfont.woff2') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Proto Mono';
  src: url('/nyata-extrabold-webfont.woff2') format('woff2'),
  url('/nyata-extrabold-webfont.woff2') format('woff');
  font-weight: 100;
}

body {
  font-family: "Nyata",Helvetica, Arial, sans-serif;
  background-color: #000 !important;
}

.App {
  width: 100%;
  overflow: hidden;
}

.hp-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1f0744;
  color: white;
  padding: 95px 18px;
  position: relative;
}

.hp-block.full {
  height: 100vh;
  background-color: transparent;
}

.hp-block ul {
  text-align: left;
  padding-bottom: 24px;
}

.hp-block h3 {
  margin-bottom: 54px;
}

.hp-block .MuiTypography-h3  {
  margin-bottom: 65px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-link {
  color: #09d3ac;
}

body {
  background-color: #edeff1;
}

@keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(1000px, 0px, 0px);
  }
}
@-webkit-keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(1000px, 0px, 0px);
  }
}

@-moz-keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(1000px, 0px, 0px);
  }
}

@-webkit-keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(1000px, 0px, 0px);
  }
}

.background-container{
  position: fixed;
  top: 0;
  left:0;
  bottom: 0;
  right: 0;
}

.stars {
  background: black url("./assets/stars.png") repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 0;
}

.twinkling{
  width:10000px;
  height: 100%;
  background: transparent url("./assets/twinkling.png") repeat;
  background-size: 1000px 1000px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;

  -moz-animation:move-background 70s linear infinite;
  -ms-animation:move-background 70s linear infinite;
  -o-animation:move-background 70s linear infinite;
  -webkit-animation:move-background 70s linear infinite;
  animation:move-background 70s linear infinite;

}

.clouds{
  width:10000px;
  height: 100%;
  background: transparent url("./assets/clouds.png") repeat;
  background-size: 1000px 1000px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;

  -moz-animation:move-background 150s linear infinite;
  -ms-animation:move-background 150s linear infinite;
  -o-animation:move-background 150s linear infinite;
  -webkit-animation:move-background 150s linear infinite;
  animation:move-background 150s linear infinite;
}
#galaxy img{
  height: 40vh;
  width: 40vh;
  position: absolute;
  z-index: 3;
  right: 55px;
  top: 80px;
}

#galaxy {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%
}

.booster {
  height: 115px;
  width: 115px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin: 18px auto;
}

.booster-title {
  padding: 15px 0;
}

.waaves {
  position: absolute;
  bottom: 100%;
  width: 100%;
  left: 0;
}

.anon {
  height: 200px;
  width: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 15%;
  margin: 18px auto;
}
