@media (max-width: 767px) {
    .waaves {
        bottom: 99%;
        width: 142%;
    }
    #galaxy img {
        height: 20vh;
        width: 20vh;
        right: 25px;
        top: 80px;
    }
    .hp-block .MuiGrid-item {
        margin-bottom: 60px;
    }
}
